<template>
  <div class="recharge-agreement">
    <div class="content">
      <h1>充值协议</h1>
      <div>
        <div>
          超算云桌面平台（以下简称“云桌面”）用户充值协议（以下简称“本协议”）是山东正云科技有限公司（以下简称“本公司”）和服务使用人（以下简称“您”）所签订的协议。为获得充值服务，请您认真阅读并充分知悉、理解云桌面的各项规则及要求，以及国家关于该类互联网信息服务的法律法规等。
          <strong>如果您对本协议的任何条款表示异议，您可以选择不使用充值服务；如您自主选择按照页面提示完成充值程序，即视为您将同意遵守本协议全部条款，以及我们后续对本协议随时所作的任何修改，并完全服从于我们的统一管理。本协议已对与您的权益具有或可能具有重大关系的条款，及对云桌面具有或可能具有免责或限制责任的条款用粗体字予以标注，请您注意。</strong>
        </div>
        <div>
          <strong>您须确认自己具有完全民事行为能力，已年满18周岁有权且有能力同意本条款及相关协议的全部内容。云桌面不鼓励未成年人使用相关服务，未成年人请委托监护人操作或在监护人明示同意下操作，否则不得使用云桌面充值服务。请未成年人用户监护人切实履行其监护义务。对于未成年用户使用充值服务过程中的行为，以及因其使用充值服务而产生的一切后果云桌面不承担任何责任。</strong>
        </div>
        <br>
        第1条：云桌面账户是指通过云桌面平台注册的个人账户，分为真实金额和赠送金额。<br>
        真实金额：用户充值时实际支付的金额账户，余额不可提现、不可退款，不可透支，不计利息。<br>
        赠送金额：用户充值时参加活动赠送的金额账户，余额不可提现、不可退款，不可透支，不计利息。<br>
        平台内所产生的消费优先从真实金额扣除，账户办理账户注销事宜请联系客服。您可通过您的云桌面账户开通、使用服务。<br>
        第2条：您可以通过预充值方式开通服务，开通后即可使用服务，您一旦充值成功，云桌面的充值服务即已提供完毕，云桌面不提供任何退还或逆向兑换人民币服务。您用以充值的账号由您独立维护、使用并独立承担全部责任，云桌面不会、也不可能参与该账号的使用等任何活动。<br>
        第3条：用户充值后，所有账户余额不能办理退款及转移。账户余额请您根据自己的消费情况进行充值，云桌面服务平台对充值次数不设限制。您确认了解并接受使用产品的价格。<br>
        第4条：您可按照云桌面充值页面上的相应提示完成充值程序，当我们通过第三方支付平台确认收到您所支付的款项后，则视为您已完成充值。充值成功后，账号内余额可由您在云桌面上自由使用。<br>
        云桌面有权根据业务发展规划、国家法律法规或监管的要求，调整、修改、完善、优化、升级或中止、取消本充值功能，您不得因此追究平台的违约或侵权责任。云桌面开展前述行动时，将在云桌面公布通知，并自公布之日起自动生效，而无需另行单独通知您，也不就该等行动给您造成的任何损失承担任何责任。<br>
        第5条：账户使用。用户在使用云桌面提供的服务后，平台会根据作业消费记录代为扣除相应费用。具体费用根据使用产品服务的价格确定。产品服务价格可以拨打客服电话进行查询。<br>
        第6条：在使用充值服务时，您务必仔细确认自己的账号并仔细选择相关操作选项。若因为您自身输入账号错误、操作不当或不了解充值计费方式等因素造成充错账号、错选充值渠道等情形而损害自身权益的，云桌面将不会作出任何补偿或赔偿。<br>
        第7条：您只能通过平台认可的渠道、通过自己的支付账户为自己的账号进行充值，而不得通过其他渠道进行充值，也不得通过他人的支付账户或其他支付手段进行充值。否则即属于违规。违规的充值将不被平台认可，从而面临充值额被扣除、账号被限制或禁止使用部分或全部功能乃至永久封号等处理。此外，违规的充值行为还可能引发盗号、诈骗等风险。<br>
        第8条：若您以非法、欺诈或利用云桌面漏洞的方式，或使用非云桌面所指定的充值方式进行充值，因此使本公司遭受损失，包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失，您应当赔偿本公司的上述全部损失。若您的行为导致任何第三方损害的，您应当独立承担责任。同时如造成您权益受损，云桌面不会作出任何补偿或赔偿，并保留随时终止您云桌面账号资格及使用各项充值服务的权利。<br>
        第9条：您不得通过云桌面充值进行任何违法犯罪活动，否则云桌面有权终止服务，情节严重的，依法移交司法机关。<br>
        第10条：如果云桌面发现因系统故障或其他任何原因导致的充值处理错误，无论有利于云桌面还是有利于您，云桌面都有权在以合理方式通知您后纠正该错误。如果该错误导致您实际充值成功却没有到达您的云桌面账户，则云桌面在确认该处理错误后会尽快将差额补足至您的云桌面账户中。如果该错误导致您实际充值失败充值金额却已到达您的云桌面账户中，则无论错误的性质和原因如何，云桌面有权从您的云桌面账户中直接扣除差额。如该等充值您已经使用的，则平台有权在您未来充值中进行扣除。<br>
        第11条：您可随时在云桌面上查看您的账户余额情况。如您对该记录有异议，应立即向云桌面提出，云桌面核对后确有错误的，将予以更正；否则您同意将云桌面上的交易记录作为充值交易的唯一有效依据。<br>
        第12条：云桌面有权基于交易安全等方面的考虑不时设定涉及交易的相关事项，包括但不限于交易限额、交易次数等。您了解，云桌面的前述设定可能对您的交易造成一定不便，您对此没有异议。<br>
        第13条：各充值方式对应的第三方渠道商可能会按其标准制定相关充值渠道手续费用（可能会在充值操作页面或渠道商其他页面上提示），并在充值时收取。请您务必注意各充值方式的渠道商服务手续费，并按自身需求选取充值方式。在任何情况下，对于您充值时涉及由第三方提供相关服务的责任由该第三方承担，云桌面不承担该等责任。您理解并同意充值后您通过第三方平台申请退款时，为验证充值/退款的真实性，第三方会向平台申请调取您的历史充值金额、历史退款金额、账号注册时长、您在云桌面的登录状态、申请退款订单购买的产品消耗程度以及您是否同意提供消费数据。届时平台会向第三方提供上述信息以便判断是否可退款。<br>
        第14条：您应确保您进行充值绑定的支付账户和微信账户开户人/持有人，可合法、有效使用该账户且未侵犯任何第三方合法权益，否则因此造成账户实际所有人损失的，您应单独负责解决由此产生的纠纷并承担全部法律责任。您应确保用于充值的资金为个人合法财产，您利用该资金充值不存在任何违法犯罪目的；如您用于充值的资金被公安机关、检察院、法院等国家机关认定为非法资金（包括但不限于非法集资、职务侵占、挪用公款或贪污受贿等所得资金），并导致该等国家机关对我们的银行账户资金采取了冻结、强制执行等措施，您应赔偿我们因此而遭受的全部损失（包括但不限于被强制执行的全部金额及相应利息、为维权而支付的公证费、诉讼费、保全费、律师费、差旅费及鉴定费等）。同时，云桌面发现（包括但不限于主动发现、收到第三方投诉或权力机关通知等情形）存在上述事由，有权单方决定限制您使用全部充值、暂停或终止向您提供充值服务或对您的帐号采取进一步管理措施；如因此给平台造成损失的，您应予以全额赔偿，同时，平台有权保存相关信息，并向相关执法/司法机构、主管部门报告。<br>
        第15条：因您自身的原因导致云桌面无法提供充值服务或提供充值服务时发生任何错误而产生的任何损失或责任，由您自行负责，云桌面不承担责任，包括但不限于：<br>
        （1）您未按照《平台用户服务协议》或本协议或云桌面不时公布的任何规则进行操作；<br>
        （2）因您的设备中云桌面应用程序失效、丢失、被封停；<br>
        （3）因您绑定的第三方支付机构账户的原因导致的损失或责任，包括您使用未经认证的第三方支付账户或使用非您本人的第三方支付账户，您的第三方支付账户被冻结、查封等；<br>
        （4）您将密码告知他人导致的财产损失；<br>
        （5）因您个人的故意或重大过失或本协议之外的第三方所造成的您财产损失。<br>
        第16条：云桌面系统因下列状况无法正常运作，使您无法使用各项服务或任何虚拟财产丢失时，云桌面不承担损害赔偿责任，该状况包括但不限于：<br>
        （1）在云桌面公告之系统停机维护、升级、调整期间；<br>
        （2）电信通讯或设备出现故障不能进行数据传输的；<br>
        （3）因台风、地震、海啸、洪水、停电、战争、恐怖袭击、政府管制等不可抗力之因素，造成云桌面系统障碍不能执行业务的；<br>
        （4）由于黑客攻击、电信部门技术调整或故障、网站升级、相关第三方的问题等原因而造成的服务中断或者延迟。<br>
        第17条 ：在任何情况下，云桌面均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害承担责任。云桌面对您承担的全部责任，无论因何原因或何种行为方式，始终不超过您因使用云桌面提供的充值服务而支付的费用。<br>
        第18条：如发生下列任何一种情形，云桌面有权随时中断或终止向您提供本协议项下的网络服务而无需通知您，且无需承担因充值服务中断或终止而给您或第三方造成的损失的任何责任：<br>
        （1）您提供的个人资料不真实；<br>
        （2）您违反本协议中规定的充值规则及保证内容；<br>
        （3）您违反《平台用户服务协议》或其他云桌面对用户的管理规定；<br>
        （4）您以营利、经营等非个人使用目的充值。<br>
        除前款所述情形外，云桌面同时保留在不事先通知您的情况下随时中断或终止部分或全部网络充值服务的权利，对于充值服务的中断或终止而造成的任何损失，云桌面无需对您或任何第三方承担任何责任。<br>
        第19条：如果您违规使用非本人手机代充值，或者通过其它非云桌面认可的渠道非法充值，则云桌面有权冻结该账户，并进行相应惩罚，严重者可以进行封号处理。<br>
        第20条：您在使用云桌面时，如出现违反国家法律法规、《平台用户服务协议》约定、本协议约定或其他云桌面公布的协议或对用户的管理规定的情形，云桌面有权暂时或永久封禁您的账号，且有权扣除云桌面的赠送/奖励 (如有)。账号封禁后至解禁（如有）前，您账户上的余额将被暂时冻结或全部扣除，不可继续用于消费云桌面上的服务，同时不予返还您充值的金额。<br>
        第21条：云桌面保留修改或增补本协议内容的权利。本协议的修改文本将公告于云桌面或以其他云桌面认为可行的方式公告。修改文本一旦公布则立即生效。若您在本协议内容发生修订后，继续使用本服务的，则视为您同意最新修订的协议内容；否则您须立即停止使用充值服务或云桌面。<br>
        第22条：为实现业务目的、保障业务安全，平台有权独立设定本功能开通的要求和开通的对象、范围，有权采用技术手段审核您使用本功能的行为，以确保您的使用不违反法律法规及本条款、相关协议之约定。此外，平台的前述审核行为并不代表平台对您使用本功能之行为的合法性进行认可或担保，对您的行为所产生的一切责任和后果仍由您独立承担。<br>
        第23条：您理解并同意，平台有权依合理判断对违反有关法律法规或本条款约定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，您应独自承担由此而产生的一切法律责任。<br>
        第24条：您理解并同意，因您违反本条款或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；若平台因此遭受损失的，您也应当一并赔偿平台的损失。<br>
        第25条：您理解并同意：若由于以下情形导致的功能中断或受阻，平台并不承担任何法律责任：（1）受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；（2）您的电脑软件、系统、硬件和通信线路出现故障；（3）您操作不当；（4）您通过非平台授权的方式使用本功能；（5）其他平台无法控制或合理预见的情形。<br>
        第26条：凡因本协议所引起的或与之相关的任何争议､纠纷､分歧或索赔，包括协议的存在､效力､解释､履行､违反或终止，或因本协议引起的或与之相关的任何非协议性争议，云桌面将与您协商解决。协商不成的，用户同意凡因本协议所引起的或与之相关的任何争议、纠纷、分歧或索赔，包括《协议》的存在、效力、解释、履行、违反或终止，或因本协议引起的或与之相关的任何非合同性争议，提交本公司住所地有管辖权的人民法院管辖。<br>
        第27条：本协议部分内容被本公司住所地有管辖权的人民法院认定为违法或无效的，不因此影响其他内容的效力。本协议未涉及的问题参见国家有关法律法规，当本协议与国家法律法规冲突时，以国家法律法规为准。<br>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.recharge-agreement{
  height: 100vh;
  overflow-y: auto;
  padding: 50px 0;
  line-height: 24px;
  .content{
    max-width: 1000px;
    margin: 0 auto;
  }
  h1{
    text-align: center;
    font-weight: bold;
  }
  h2,h3{
    padding-top: 15px;
    font-weight: 600;
  }
}

</style>
